'use client'

import { Me, useUserStore } from '@/store/useUserStore'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import styles from './AppInfo.module.scss'

interface AppInfoProps {
  buildId: string
  version: string
  envName: string
  gitLog: string
}

interface Commit {
  hash: string
  author: string
  date: string
  message: string
  body: string
}

export default function AppInfo({
  buildId,
  version,
  envName,
  gitLog,
}: AppInfoProps) {
  const [isLogVisible, setIsLogVisible] = useState(false)
  const [isUserInfoVisible, setIsUserInfoVisible] = useState(false)
  const user = useUserStore((state) => state.me) as Me | null

  const isLoggedIn = !!user

  const toggleLog = () => {
    setIsLogVisible(!isLogVisible)
    setIsUserInfoVisible(false)
  }

  const toggleUserInfo = () => {
    setIsUserInfoVisible(!isUserInfoVisible)
    setIsLogVisible(false)
  }

  const parsedCommits = useMemo(() => {
    if (!gitLog) return []

    const commits: Commit[] = []
    const lines = gitLog.split('\n')
    let currentCommit: Partial<Commit> = {}

    lines.forEach((line) => {
      if (line.startsWith('commit ')) {
        if (Object.keys(currentCommit).length) {
          commits.push(currentCommit as Commit)
        }
        currentCommit = { hash: line.split(' ')[1] }
      } else if (line.startsWith('Author: ')) {
        currentCommit.author = line.substring(8)
      } else if (line.startsWith('Date: ')) {
        currentCommit.date = line.substring(6)
      } else if (line.trim() !== '') {
        if (currentCommit.message) {
          currentCommit.body = (currentCommit.body || '') + line.trim() + '\n'
        } else {
          currentCommit.message = line.trim()
        }
      }
    })

    if (Object.keys(currentCommit).length) {
      commits.push(currentCommit as Commit)
    }

    return commits
  }, [gitLog])

  const [isClient, setIsClient] = useState(false)
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    setIsClient(true)
  }, [])

  useEffect(() => {
    setIsVisible(true)
  }, [])

  const handleClose = () => {
    setIsVisible(false)
  }

  const latestCommitDate = useMemo(() => {
    if (parsedCommits.length > 0) {
      const date = dayjs(parsedCommits[0].date)
      return date.isValid() ? date.format('YYYY-MM-DD HH:mm:ss') : '확인 어려움'
    }
    return '확인 어려움'
  }, [parsedCommits])

  const renderUserInfo = () => {
    if (!user) return null

    const fieldsToShow = [
      '_id',
      'idx',
      'createdAt',
      'email',
      'loginType',
      'name',
      'role',
      'setting',
      'uid',
      'updatedAt',
      'restaurants',
      'status',
    ]

    const formatValue = (field: string, value: any) => {
      switch (field) {
        case 'setting':
          return (
            <ul className={styles.userInfoNestedList}>
              {Object.entries(value).map(([key, val]) => (
                <li
                  key={key}
                  className={styles.userInfoNestedItem}
                >{`${key}: ${JSON.stringify(val)}`}</li>
              ))}
            </ul>
          )
        case 'restaurants':
          return (
            <ul className={styles.userInfoNestedList}>
              {value.map((restaurant: any, index: number) => (
                <li
                  key={index}
                  className={styles.userInfoNestedItem}
                >{`${restaurant.name} (${restaurant.idx})`}</li>
              ))}
            </ul>
          )
        default:
          return JSON.stringify(value)
      }
    }

    return (
      <>
        {fieldsToShow.map((field) => (
          <div key={field} className={styles.userInfoItem}>
            <span className={styles.userInfoLabel}>{field}:</span>
            <span className={styles.userInfoValue}>
              {formatValue(field, user[field as keyof typeof user])}
            </span>
          </div>
        ))}
      </>
    )
  }

  const formattedBuildId = buildId ? parseInt(buildId, 10).toString() : 'local'

  if (!isVisible) return null

  return (
    <>
      <div className={styles.appInfoBottom}>
        <div className={styles.appInfoContent}>
          <span className={styles.appInfoLabel}>빌드:</span>
          <span className={styles.appInfoValue}>
            {formattedBuildId} | v{version} ({envName})
          </span>
          {gitLog && (
            <>
              <span className={styles.appInfoSeparator}>|</span>
              <span className={styles.appInfoLabel}>업데이트:</span>
              <span className={styles.appInfoValue}>
                {isClient ? latestCommitDate : '로딩 중...'}
              </span>
            </>
          )}
        </div>
        <div className={styles.appInfoButtons}>
          {gitLog && (
            <button onClick={toggleLog} className={styles.appInfoToggle}>
              {isLogVisible ? '로그 숨기기' : '로그 보기'}
            </button>
          )}
          {isLoggedIn && (
            <button onClick={toggleUserInfo} className={styles.appInfoToggle}>
              {isUserInfoVisible ? '계정정보 숨기기' : '로그인계정 정보'}
            </button>
          )}
          <button onClick={handleClose} className={styles.appInfoToggle}>
            닫기
          </button>
        </div>
      </div>
      {isLogVisible && gitLog && (
        <div className={styles.appInfoLogContainer}>
          <ul className={styles.appInfoLogList}>
            {parsedCommits.map((commit, index) => (
              <li key={index} className={styles.appInfoLogItem}>
                <div className={styles.appInfoLogHash}>
                  {commit.hash ? commit.hash.substring(0, 7) : 'N/A'}
                </div>
                <div className={styles.appInfoLogMessage}>
                  {commit.message || 'No message'}
                </div>
                {commit.body && (
                  <div className={styles.appInfoLogBody}>{commit.body}</div>
                )}
                <div className={styles.appInfoLogDetails}>
                  {commit.author || 'Unknown'} |{' '}
                  {commit.date
                    ? dayjs(commit.date).format('YYYY-MM-DD HH:mm:ss')
                    : 'Unknown date'}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      {isUserInfoVisible && (
        <div className={styles.appInfoLogContainer}>
          <div
            className={`${styles.userInfoContent} ${styles.userInfoFontSize}`}
          >
            <h3 className={styles.userInfoTitle}>사용자 정보</h3>
            {renderUserInfo()}
          </div>
        </div>
      )}
    </>
  )
}
